body {
  max-width: 100%;
  overflow-x: hidden; /* Döljer horisontell scrollning om innehållet överstiger bredden */
  margin: 0px;
  height: 100%;
  font-family: "Trebuchet Ms";
  background-color: #fbfbfd;
}
.App {
  max-width: 100%;
  margin: 0;
}
#content {
  min-height: 100vh;
}
a {
  text-decoration: none;
}
h1 {
  text-align: center;
  line-height: 1.5;
  color: white;
  font-family: "Trebuchet Ms";
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 3px;
}
h2 {
  font-size: 20px;
  color: #444249;
}
h3 {
  color: #444249;
  text-align: center;
  font-size: 17px;
  line-height: 1.5;
}
p {
  font-size: 14px;
  color: #444249;
}

/*Styling for header*/
header {
  background-color: #88889c;
  height: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
}
#title-div {
  width: 60%;
  justify-content: center;
  display: flex;
  cursor: pointer;
  letter-spacing: 2px;
}
#pilot {
  color: white;
}
#logo-div {
  width: 20%;
  display: flex;
  justify-content: right;
  margin-right: 100px;
}
#logo {
  width: 90%;
  height: auto;
  max-width: 170px;
  margin-right: 5px;
}

/*Styling for navigation bar*/
.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #666672;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
}
.navbar li {
  display: block;
  color: white;
  text-align: center;
  padding: 17px 50px;
  text-decoration: none;
  letter-spacing: 3px;
  cursor: pointer;
}
.navbar li:hover {
  background-color: #595964;
}

/*Styling for the textcontent about the calculator*/
#description-div {
  height: auto;
  padding: 20px;
  margin: 0px;
  text-align: center;
  line-height: 1.5;
  font-family: "Trebuchet Ms";
  text-align: center;
  background-color: #ededf5;
}
#description {
  margin-left: auto;
  margin-top: -20px;
  margin-right: auto;
  color: #5f5f63;
  font-size: 16px;
  width: 80%;
}
#description-title {
  font-size: 22px;
}

/*Styling for patient category buttons*/
#buttons-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.patient-category {
  margin: 15px auto;
  width: 100%;
  height: 100px;
  background-color: #88889c44;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
  border: none;
  font-family: "Trebuchet Ms";
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 15px;
  color: #2e2e2e;
}
.patient-category:hover {
  background-color: #88889c9f;
  cursor: pointer;
}

/*Styling for the calculator*/
#flex-container {
  display: flex;
  justify-content: center;
}
#calculator {
  width: 50%;
  height: auto;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}
label {
  margin-left: 4px;
}
#question-mark {
  margin-top: 13px;
  margin-left: 4px;
  color: #88889c;
  font-size: 13px;
}
#question-mark:hover {
  color: #565663;
  cursor: pointer;
}
#question-mark-radio {
  margin-top: 8px;
  margin-left: 4px;
  color: #88889c;
  font-size: 13px;
  cursor: pointer;
}
#question-mark-radio:hover {
  color: #565663;
  cursor: pointer;
}
.variable-information {
  font-size: 13px;
  color: #686769;
  margin: 20px 0px 20px 3px;
  font-style: italic;
  font-weight: bold;
  width: 60%;
}

/*Styling for the form input elements*/
.radiobutton-container {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.radiobutton-name {
  margin-right: 20px;
  vertical-align: text-bottom;
}
input[type="radio"] {
  filter: grayscale(1);
  width: 15px;
  height: 15px;
  vertical-align: text-bottom;
  margin-bottom: 1px;
  margin-right: 10px;
}
#radiobuttons-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: flex-end;
}
#calculator-title {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(98, 97, 114);
  font-weight: bold;
  margin-left: 3px;
}
#under-title {
  font-size: 14px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(74, 72, 78);
  margin-top: -5px;
  margin-bottom: 30px;
  margin-left: 3px;
}
#return-arrow {
  font-size: 20px;
  color: #88889c;
  cursor: pointer;
}
#return-arrow:hover {
  color: #4f4f58;
}
input[type="text"] {
  width: 30px;
  height: 16px;
  padding: 3px;
  margin-top: 4px;
  text-align: center;
  background-color: transparent;
  border: 1px solid rgba(236, 111, 111, 0.582);
}
#input-text {
  margin-top: 7px;
  margin-right: 6px;
  margin-left: 4px;
}
#text-input-div {
  margin-bottom: 10px;
  width: 80%;
  position: relative;
}
label {
  position: relative;
}
#labels {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 15px;
  font-size: 14px;
  line-height: 2.1;
  margin-top: 6px;
}
.radio-pair {
  margin-left: auto;
}
#yes-no {
  word-spacing: 8px;
  font-size: 12px;
  text-align: left;
  margin-left: auto;
  margin-right: 9px;
  font-weight: 600;
}
input[type="range"] {
  margin-bottom: 20px;
  margin-top: 20px;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #c0b6ca;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #565663;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #565663;
  cursor: pointer;
}
.form-buttons-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  margin-left: 10px;
}
#calculate-button,
#reset-button {
  width: 250px;
  height: 40px;
  background-color: rgba(128, 163, 128, 0.459);
  border: none;
  padding: 10px;
  margin-top: 25px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  cursor: pointer;
  box-shadow: rgba(101, 101, 101, 0.2) 0px 8px 24px;
  color: rgb(34, 34, 34);
}
#calculate-button:hover {
  background-color: rgba(128, 163, 128, 0.593);
}
#reset-button {
  background-color: rgb(214, 213, 213);
  width: 100px;
  height: 30px;
}
#reset-button:hover {
  background-color: rgb(166, 166, 166);
}
#text-input-div label {
  position: relative;
  display: inline-block; /* Ensure label takes the width of its contents */
}
#popup-info {
  text-align: center;
  font-size: 12px;
  color: black;
  padding: 10px;
  border-radius: 2px;
  position: absolute;
  border: 1px solid #d8d8df;
  z-index: 2;
  width: 170px;
  background-color: #ededf5;
  bottom: 100%; /* Position above the parent */
  left: 100%; /* Center horizontally */
  transform: translateX(-50%);
  margin-bottom: -5px; /* Adjust the distance above the FontAwesome icon */
}
#error-popup {
  width: 30%;
}

/*Styling for result and diagram*/
svg {
  margin-top: -40px;
}
.result {
  margin-top: 30px;
  display: flex;
  width: 80%;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 20px #ededf5; /* Inside border style */
}
.result-textbox {
  width: 80%;
  text-align: center;
  margin-top: -20px;
}
#pdf {
  color: rgb(123, 122, 129);
  font-size: 26px;
  cursor: pointer;
  margin-left: 90%;
  margin-top: 30px;
  display: none;
}
#pdf:hover {
  color: rgb(139, 137, 170);
}
#result-text {
  width: 84%;
  padding: 20px;
  margin-top: 30px;
  text-align: center;
  line-height: 2;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(88, 88, 88);
}
#compare-box {
  background-color: #ededf5;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  padding: 15px;
  font-size: 12px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(74, 72, 78);
  margin-bottom: 40px;
}

/* popup background */
.popup {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* popup for missing variable */
.popup-content {
  background-color: #f0ecec;
  margin: 15% auto;
  padding: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 40%;
  height: auto;
}
#popup-text {
  padding-top: 10px;
  font-size: 14px;
  margin-left: 10px;
  line-height: 1.5;
}
#popup-title {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

/*popup for disclaimer*/
.checkbox-label {
  vertical-align: text-bottom;
  margin-top: 30px;
  margin-left: 5px;
}
#disclaimer-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}
#disclaimer-text {
  padding-top: 10px;
  font-size: 14px;
  margin-left: 10px;
}
.disclaimer-content {
  background-color: #f0ecec;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 600px;
  min-height: 250px;
}
#disclaimer-button {
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 40px;
  width: 100px;
  background-color: rgba(128, 163, 128, 0.459);
  border: none;
  padding: 10px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  cursor: pointer;
  box-shadow: rgba(101, 101, 101, 0.2) 0px 8px 24px;
  color: rgb(34, 34, 34);
}
#disclaimer-button:hover {
  cursor: pointer;
  background-color: rgb(125, 179, 125);
}
#disclaimer-button:disabled {
  pointer-events: none; /* Prevent any mouse events (including hover) */
  opacity: 0.5; /* Optional: reduce opacity to visually indicate the button is disabled */
}
input[type="checkbox"] {
  filter: grayscale(1);
}
#agree-checkbox {
  font-size: 13px;
}

/* The ok button */
.ok-button {
  margin-left: 10px;
  margin-bottom: 20px;
  width: 100px;
  background-color: rgba(128, 163, 128, 0.459);
  border: none;
  padding: 10px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  cursor: pointer;
  box-shadow: rgba(101, 101, 101, 0.2) 0px 8px 24px;
  color: rgb(34, 34, 34);
}

.ok-button:hover {
  cursor: pointer;
  background-color: rgb(125, 179, 125);
}

/*Styling for charts*/
#circle-and-text {
  margin-top: 60px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  visibility: hidden;
}
#diagram-title {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(98, 97, 114);
  font-weight: bold;
}
.circle-diagram {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.percent-result {
  margin: 5px 20px;
  height: 220px;
  width: 220px;
  transform: translate(0%, 0%);
}
.percent-progress {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  display: grid;
  position: relative;
  place-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.percent-progress:before {
  content: "";
  position: absolute;
  height: 80%;
  width: 80%;
  background-color: #e7e6e6;
  border-radius: 50%;
}
.percent-values {
  position: relative;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #a09ca0;
  white-space: pre;
  line-height: 1.2;
  margin-top: 10px;
  vertical-align: middle;
}
#circle-text {
  font-size: 25px;
  font-weight: bold;
  color: #8e8d97;
}

/*Styling for page about GCRC*/
h3 {
  color: #4a4a4b;
  font-size: 18px;
  margin-bottom: 30px;
}
.image-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.image-and-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  border-radius: 3px;
  margin-bottom: 20px;
  width: 180px;
}
.small-container {
  width: 100%;
  background-color: #ededf5;
  text-align: center;
  padding: 20px;
}
.information-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
#big-title {
  font-size: 25px;
  color: rgb(95, 101, 110);
}
#smaller-title {
  font-size: 22px;
  color: rgb(94, 97, 102);
}
.information-text {
  width: 50%;
  line-height: 2;
  font-size: 16px;
  font-family: "Trebuchet Ms";
  color: #5f5f63;
  margin-left: auto;
  margin-right: auto;
}
.information-content {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 40px;
}
.previous-work {
  color: #1c1c1d;
  font-family: Arial, Helvetica, sans-serif;
}
.gcrc-people {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 180px;
}
.names {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: rgb(94, 97, 102);
}
.previous-work {
  color: #1c1c1d;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (min-width: 541px) and (max-width: 1024px) {
  #calculator {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #text-input-div {
    width: 85%;
    font-size: 12px;
  }
  #title-div {
    width: 90%;
  }
  #page-title {
    font-size: 18px;
  }
  #logo-div {
    margin-right: 10px;
  }
  h3 {
    font-size: 16px;
  }
  #description {
    font-size: 14px;
  }
  .radiobutton-container {
    width: 85%;
    margin-right: 0%;
    font-size: 12px;
  }
  .variable-information {
    font-size: 11px;
    line-height: 1.5;
  }
  .form-buttons-div {
    width: 85%;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  #buttons-div {
    width: 80%;
    margin-bottom: 30px;
  }
  .information-text {
    width: 80%;
  }
  .information-content {
    width: 80%;
    margin-top: 40px;
  }
  .previous-work {
    color: #1c1c1d;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.5;
  }
  .names {
    font-size: 12px;
    margin-top: 2px;
  }
  .gcrc-people {
    width: 160px;
  }
  .image-and-name-container {
    padding: 0px;
    width: 160px;
  }
  .images-container {
    width: 100%;
  }
  #error-popup {
    width: 60%;
  }
  .result {
    margin-bottom: 50px;
    width: 85%;
  }
}

@media screen and (min-width: 281px) and (max-width: 540px) {
  #calculator {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .result {
    box-shadow: 0 0 0 10px #ededf5; /* Inside border style */
    width: 85%;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  .disclaimer-content {
    width: 60%;
  }
  #under-title {
    width: 80%;
    text-align: center;
  }
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #text-input-div {
    width: 90%;
    font-size: 12px;
  }
  #title-div {
    width: 90%;
  }
  #page-title {
    font-size: 18px;
  }
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #logo-div {
    margin-right: 0px;
    margin-top: -10px;
  }
  .navbar {
    flex-direction: column;
    font-size: 14px;
  }
  h3 {
    font-size: 16px;
  }
  #description {
    font-size: 14px;
  }
  .radiobutton-container {
    width: 90%;
    margin-right: 0%;
    font-size: 12px;
  }
  .variable-information {
    font-size: 11px;
    line-height: 1.5;
  }
  .form-buttons-div {
    width: 85%;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  #buttons-div {
    width: 80%;
    margin-bottom: 30px;
  }
  .information-text {
    width: 90%;
    line-height: 2;
    font-size: 13px;
    padding: 0px;
  }
  .information-content {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding: 10px;
  }
  .previous-work {
    color: #1c1c1d;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.5;
  }
  #big-title {
    font-size: 20px;
  }
  .names {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.5;
  }
  .gcrc-people {
    margin: 0px;
    width: 90px;
  }
  .image-row {
    height: auto;
  }
  .image-and-name-container {
    width: 90px;
    padding: 0px;
  }
  .information-container {
    width: 100%;
  }
  .images-container {
    width: 100%;
    margin-top: -20px;
  }
  #error-popup {
    width: 80%;
  }
  #under-title {
    margin: 10px auto 20px auto;
  }
  .form-buttons-div {
    width: 90%;
    margin-left: 5px;
  }
}
@media screen and (max-width: 280px) {
  #calculator {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .result {
    box-shadow: 0 0 0 10px #ededf5; /* Inside border style */
    width: 85%;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  .disclaimer-content {
    width: 60%;
  }
  #under-title {
    width: 80%;
    text-align: center;
  }
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #text-input-div {
    width: 90%;
    font-size: 12px;
  }
  #title-div {
    width: 90%;
  }
  .patient-category {
    font-size: 12px;
  }
  #page-title {
    font-size: 18px;
  }
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #logo-div {
    margin-right: 0px;
    margin-top: -10px;
  }
  .navbar {
    flex-direction: column;
    font-size: 14px;
  }
  h3 {
    font-size: 16px;
  }
  #description {
    font-size: 12px;
  }
  .radiobutton-container {
    width: 90%;
    margin-right: 0%;
    font-size: 10px;
    word-spacing: 0px;
  }
  #yes-no {
    margin-right: 0px;
    font-size: 10px;
    margin-bottom: -5px;
  }
  input[type="radio"] {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
  .variable-information {
    font-size: 11px;
    line-height: 1.5;
  }
  .form-buttons-div {
    width: 85%;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  #buttons-div {
    width: 80%;
    margin-bottom: 30px;
  }
  .information-text {
    width: 90%;
    line-height: 2;
    font-size: 13px;
    padding: 0px;
  }
  .information-content {
    display: flex;
    flex-direction: column;
    width: 85%;
    padding: 10px;
  }
  .previous-work {
    color: #1c1c1d;
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.5;
  }
  #big-title {
    font-size: 20px;
  }
  .names {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.5;
  }
  .gcrc-people {
    margin: 0px;
    width: 70px;
  }
  .image-row {
    height: auto;
  }
  .image-and-name-container {
    width: 70px;
    padding: 0px;
  }
  .information-container {
    width: 100%;
  }
  .images-container {
    width: 100%;
    margin-top: -20px;
  }
  #error-popup {
    width: 80%;
  }
  #under-title {
    margin: 10px auto 20px auto;
  }
  .form-buttons-div {
    width: 90%;
    margin-left: 5px;
  }
}
